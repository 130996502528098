import React, { FC } from 'react';

import LayoutA from './LayoutA';
import LayoutB from './LayoutB';
import LayoutC from './LayoutC';

import * as Types from '../../lib/types';

export type TabsProps = {
  /**
   * Layout A: traditional tabs with images above the content
   * Layout B: scrolls images horizontally based on active tab
   * Layout C: acts like an accordion on desktop and mobile
   */
  layout: Types.AccordionLayout;
  items: Types.AccordionItem[] | Types.Product[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  darkMode: boolean;
  cta?: Types.ButtonLink;
  className?: string;
};

const Tabs: FC<TabsProps> = ({
  layout,
  items,
  activeTab,
  setActiveTab,
  cta,
  className,
  darkMode
}) => {
  const renderLayout = () => {
    switch (layout) {
      case 'layout-a':
        return (
          <LayoutA
            layout={layout}
            items={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            cta={cta}
            darkMode={darkMode}
            className={className}
          />
        );
      case 'layout-b':
        return (
          <LayoutB
            layout={layout}
            items={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            cta={cta}
            darkMode={darkMode}
            className={className}
          />
        );
      case 'layout-c':
        return (
          <LayoutC
            layout={layout}
            items={items}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            cta={cta}
            className={className}
          />
        );
    }
  };

  return renderLayout();
};

export default Tabs;
