import React, { FC } from 'react';
import ReactPlayer from 'react-player/lazy';
import cx from 'classnames';

import contentfulImgUtil from 'utils/contentfulImgUtil';

import * as Types from '../../../lib/types';

type VideoProps = {
  video: Types.Video;
  loop?: boolean;
  muted?: boolean;
  autoPlay?: boolean;
  controls?: boolean;
  width?: string;
  height?: string;
  className?: string;
};

const Video: FC<VideoProps> = ({
  video,
  loop = true,
  muted = true,
  autoPlay = true,
  controls = false,
  width = 'auto',
  height = '100%',
  className
}) => {
  const isYouTube = video.url.includes('youtube.com');

  return (
    <ReactPlayer
      className={cx(isYouTube && 'youtube-player', className)}
      url={video.url}
      width={width}
      height={height}
      playsinline
      loop={loop}
      muted={muted}
      playing={autoPlay}
      controls={controls}
      config={{
        file: {
          attributes: {
            poster: contentfulImgUtil(
              video.posterImage.url,
              '1600',
              'webp',
              '70'
            )
          }
        }
      }}
    />
  );
};

export default Video;
