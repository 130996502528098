import * as Types from '../../lib/types';

const Detail_1: Types.Detail = {
  _type: 'detailItem',
  _key: 'detail_1',
  plainTextTitle: 'Things for going light. ',
  titleLink: {
    _type: 'link',
    label: 'Shop Light Phones & accessories.',
    internal: {
      _id: 'shop',
      _type: 'genericPage',
      slug: '/shop'
    },
    ariaLabel: 'Shop Light Phones & accessories.'
  },
  image: {
    _type: 'accessibleImage',
    _key: 'detail_1_image',
    asset: {
      _id: '2g2PiO52WwNRzIZYnVokh2',
      url: '//images.ctfassets.net/tbduj203nkkk/2g2PiO52WwNRzIZYnVokh2/7a2482e1dc204ed2c842253ad51f65ff/BothLightPhones.Scale.png',
      metadata: {
        lqip: '',
        dimensions: {
          aspectRatio: 1,
          width: 1000,
          height: 1000
        }
      }
    },
    alt: 'Product shot of the Light Phone III (front).'
  }
};

const Detail_2: Types.Detail = {
  _type: 'detailItem',
  _key: 'detail_2',
  plainTextTitle: 'Questions? ',
  titleLink: {
    _type: 'link',
    label: 'Read our FAQ.',
    internal: {
      _id: 'faq',
      _type: 'genericPage',
      slug: '/faq'
    },
    ariaLabel: 'Read our FAQ.'
  },
  // max 3 items
  list: [
    'Can it work with my existing phone?',
    'Will this work for my carrier?',
    'Does it have Bluetooth/WiFi?'
  ]
};

const Detail_3: Types.Detail = {
  _type: 'detailItem',
  _key: 'detail_3',
  plainTextTitle: 'Get set up with service on your Light Phone.',
  links: [
    {
      _type: 'buttonLink',
      label: 'Check carrier compatibility',
      link: {
        _type: 'link',
        internal: {
          _id: 'compatibility-checker',
          _type: 'genericPage',
          slug: '/compatibility-checker'
        },
        ariaLabel: 'Check carrier compatibility.'
      }
    },
    {
      _type: 'buttonLink',
      label: 'The Light Service Plan',
      link: {
        _type: 'link',
        internal: {
          _id: 'plans',
          _type: 'genericPage',
          slug: '/plans'
        },
        ariaLabel: 'Learn more about the Light Service Plan'
      }
    }
  ]
};

const Detail_4: Types.Detail = {
  _type: 'detailItem',
  _key: 'detail_4',
  plainTextTitle: 'Follow along our journey ',
  titleLink: {
    _type: 'link',
    label: 'on our blog.',
    internal: {
      _id: 'blog',
      _type: 'genericPage',
      slug: '/blog'
    },
    ariaLabel: 'Read the Light blog'
  },
  links: [
    {
      _type: 'buttonLink',
      label: 'pgLang & Light Collaboration',
      link: {
        _type: 'link',
        internal: {
          _id: 'light-x-pglang-collaboration',
          _type: 'genericPage',
          slug: '/blog/light-x-pglang-collaboration'
        },
        ariaLabel: 'Read about the pgLang & Light Collaboration'
      }
    },
    {
      _type: 'buttonLink',
      label: 'Buxton School Goes Light',
      link: {
        _type: 'link',
        internal: {
          _id: 'buxton-school-goes-light',
          _type: 'genericPage',
          slug: '/blog/buxton-school-goes-light'
        },
        ariaLabel: 'Read about the Buxton School going Light'
      }
    }
  ]
};

const Details_1: Types.DetailItems = {
  _type: 'detailSection',
  _key: 'details_1',
  detailItems: [Detail_1, Detail_2]
};

const Details_2: Types.DetailItems = {
  _type: 'detailSection',
  _key: 'details_2',
  detailItems: [Detail_3, Detail_4]
};

export const DetailsBlockData: Types.DetailsBlock = {
  _type: 'detailsBlock',
  _key: 'detailsBlock_1',
  isDarkMode: false,
  detailSections: [Details_1, Details_2]
};
