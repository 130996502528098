import { createClient } from '@sanity/client';
import ImageUrlBuilder from '@sanity/image-url';

import * as Config from '../config';
import * as Types from '../types';

export const sanityClient = createClient({
  projectId: Config.SANITY_PROJECT_ID,
  dataset: Config.SANITY_DATASET,
  apiVersion: Config.SANITY_API_VERSION,
  useCdn: true
});

export const imageBuilder = ImageUrlBuilder(sanityClient);

// Field Queries
const IMAGE_QUERY = `
    _type,
    _key,
    asset -> {
      _id,
      _type,
      url,
      metadata {
        lqip,
        dimensions {
          aspectRatio,
          width,
          height
        }
      }
    },
    alt,
    applyScreenBlend,
    crop,
    hotspot
`;

const VIDEO_QUERY = `
  _type,
  posterImage {${IMAGE_QUERY}},
  caption,
  applyScreenBlend,
  "url": asset->url
`;

const LINK_QUERY = `
  _type,
  openInNewTab,
  ariaLabel,
  internal-> {
    _id,
    _type,
    "slug": slug.current
  },
  external
`;

const BUTTON_LINK_QUERY = `
  _type,
  label,
  link {${LINK_QUERY}},
  variant
`;

const BARE_BONES_RICHTEXT_WITH_LINK_QUERY = `
    ...,
    _type,
    _key,
    markDefs[] {
    _key,
    _type == "link" => {
      ${LINK_QUERY}
    }
  },
`;

const FOREGROUND_MEDIA_QUERY = `
  _type,
  "media": media{
    _type == "accessibleImage" => {${IMAGE_QUERY}},
    _type == "video" => {${VIDEO_QUERY}}
  }[0],
  video {${VIDEO_QUERY}}
`;

const BACKGROUND_MEDIA_QUERY = `
  _type,
  "desktop": desktop{
    _type == "accessibleImage" => {${IMAGE_QUERY}},
    _type == "video" => {${VIDEO_QUERY}}
  }[0],
  "mobile": mobile{
    _type == "accessibleImage" => {${IMAGE_QUERY}},
    _type == "video" => {${VIDEO_QUERY}}
  }[0]
`;

const METADATA_QUERY = `
  _type,
  title,
  shareTitle,
  description,
  shareDescription,
  noIndex,
`;

const ACCORDION_ITEM_QUERY = `
  _type,
  _key,
  title,
  body[] {${BARE_BONES_RICHTEXT_WITH_LINK_QUERY}},
  image {${IMAGE_QUERY}},
  backgroundMedia {${BACKGROUND_MEDIA_QUERY}}
`;

const DETAIL_ITEM_QUERY = `
  _type,
  _key,
  title[] {${BARE_BONES_RICHTEXT_WITH_LINK_QUERY}},
  body[] {${BARE_BONES_RICHTEXT_WITH_LINK_QUERY}},
  image {${IMAGE_QUERY}},
  list[],
  links[] {${BUTTON_LINK_QUERY}},
  ctas[] {${BUTTON_LINK_QUERY}}
`;

// Block Queries
const HEADER_BLOCK_QUERY = `
  showBorderTop,
  title,
  backgroundMedia {${BACKGROUND_MEDIA_QUERY}}
`;

const ACCORDION_BLOCK_QUERY = `
  showBorderTop,
  title,
  accordionItems[] {${ACCORDION_ITEM_QUERY}},
  cta {${BUTTON_LINK_QUERY}},
  bottomSpacer
`;

const DETAILS_BLOCK_QUERY = `
  detailSections[] {
    _key,
    _type,
    detailItems{${DETAIL_ITEM_QUERY}}[]
  }
`;

const FAQ_BLOCK_QUERY = `
  title,
  showBorderTop,
  layout,
  faqItems[] {${ACCORDION_ITEM_QUERY}},
  backgroundMedia,
  bottomSpacer
`;

const SEARCH_BLOCK_QUERY = `
  title,
  showBorderTop,
  backgroundMedia
`;

const SINGLE_IMAGE_BLOCK_QUERY = `
  title,
  showBorderTop,
  image {${IMAGE_QUERY}},
  imageSize
`;

const TEXT_BLOCK_QUERY = `
  showBorderTop,
  title,
  backgroundMedia {${BACKGROUND_MEDIA_QUERY}},
  foregroundMedia {${FOREGROUND_MEDIA_QUERY}},
  body[] {${BARE_BONES_RICHTEXT_WITH_LINK_QUERY}},
  cta {${BUTTON_LINK_QUERY}}
`;

const HEADER_AREA_QUERY = `
  _type,
  _key,
  isDarkMode,
  _type == "headerBlock" => {${HEADER_BLOCK_QUERY}}
`;

const CONTENT_AREA_QUERY = `
  _type,
  _key,
  isDarkMode,
  _type == "accordionBlock" => {${ACCORDION_BLOCK_QUERY}},
  _type == "detailsBlock" => {${DETAILS_BLOCK_QUERY}},
  _type == "faqBlock" => {${FAQ_BLOCK_QUERY}},
  _type == "searchBlock" => {${SEARCH_BLOCK_QUERY}},
  _type == "singleImageBlock" => {${SINGLE_IMAGE_BLOCK_QUERY}},
  _type == "textBlock" => {${TEXT_BLOCK_QUERY}}
`;

// Document Queries
export const GENERIC_PAGE_QUERY = `
    _id,
    _type,
    title,
    "slug": slug.current,
    headerArea{${HEADER_AREA_QUERY}}[],
    blocks{${CONTENT_AREA_QUERY}}[],
    metadata {${METADATA_QUERY}},
    shareGraphic {
      asset->{
        url
      }
    }
`;

export const GenericPage = {
  all() {
    return sanityClient.fetch<Types.GenericPage[]>(
      `*[_type == 'genericPage'] {${GENERIC_PAGE_QUERY}}`
    );
  },
  findBy({ slug }: { slug: string }) {
    return sanityClient.fetch<Types.GenericPage>(
      `*[_type == "genericPage" && slug.current == $slug][0] {${GENERIC_PAGE_QUERY}}`,
      { slug }
    );
  }
};
