import React, { FC, useRef, useCallback } from 'react';
import cx from 'classnames';

import { TabsProps } from './index';
import TabImage from './TabImage';
import Tablist from './Tablist';
import Pagination from './Pagination';

type LayoutBProps = TabsProps & {
  darkMode: boolean;
};

const LayoutB: FC<LayoutBProps> = ({
  layout,
  items,
  activeTab,
  setActiveTab,
  cta,
  darkMode,
  className
}) => {
  const tabContainerRef = useRef<HTMLDivElement>(null);
  const imageContainerRef = useRef<HTMLDivElement>(null);

  const handleActiveChange = useCallback(
    (ref: React.RefObject<HTMLDivElement>) => {
      if (!ref.current) return;
      if (!imageContainerRef.current) return;
      const newLeft = -ref.current.offsetLeft;
      imageContainerRef.current.style.left = newLeft + 'px';
    },
    [imageContainerRef]
  );
  return (
    <div ref={tabContainerRef} className={cx('tab-wrapper', className)}>
      {/* Tab Image Container (layout b) */}
      <div className="tab-image-wrapper-layout-b">
        <div ref={imageContainerRef} className="tab-image-container-layout-b">
          {items.map((item, index) => (
            <TabImage
              id={`tab-content-${item._id}`}
              image={item.image}
              key={item._id}
              isActive={activeTab === index}
              onActive={handleActiveChange}
              index={index}
              setActiveTab={setActiveTab}
            />
          ))}
        </div>
      </div>

      {/* Pagination - only visible on mobile */}
      <Pagination
        items={items}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        darkMode={darkMode}
      />

      {/* Tabs */}
      <Tablist
        layout={layout}
        items={items}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        cta={cta}
      />
    </div>
  );
};

export default LayoutB;
