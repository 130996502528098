import React from 'react';
import PropTypes from 'prop-types';

import contentfulImgUtil from 'utils/contentfulImgUtil';

import { Image } from 'components/base';
import ErrorMessage from 'components/ErrorMessage';
import SEO from 'components/SEO';

const ErrorPage = ({
  backgroundImage,
  logo,
  errorMessage,
  buttonLabel,
  buttonLink
}) => {
  return (
    <div className="ErrorPage">
      <SEO title='404' />
      <Image
        className="fixed z-overlay vh100 vw100"
        bg={true}
        style={{
          backgroundSize: 'cover',
          backgroundImageRepeat: 'no-repeat'
        }}
        alt={backgroundImage.title}
        src={contentfulImgUtil(backgroundImage.url, '1600')}
      >
        <div className="wh100 relative flex flex-col justify-center items-center">
          <ErrorMessage
            logo={logo}
            errorMessage={errorMessage}
            buttonLabel={buttonLabel}
            buttonLink={buttonLink}
          />
        </div>
      </Image>
    </div>
  );
};

ErrorPage.propTypes = {
  backgroundImage: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  }),
  logo: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string
  }),
  errorMessage: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string
};

ErrorPage.defaultProps = {
  backgroundImage: {
    title: '',
    url: ''
  },
  logo: {
    title: '',
    url: ''
  },
  errorMessage: 'Something went wrong',
  buttonLabel: 'return to home',
  buttonLink: '/'
};

export default ErrorPage;
