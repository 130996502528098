import React, { FC, useEffect } from 'react';
import cx from 'classnames';

import { Image } from 'components/base';

import contentfulImgUtil from 'utils/contentfulImgUtil';
import { getSrcSet } from 'utils/getSrcSet';

import * as Types from '../../lib/types';

type TabImageProps = {
  id: string;
  isActive: boolean;
  image?: Types.AccessibleImage | null;
  onActive: (ref: React.RefObject<HTMLDivElement>) => void;
  index: number;
  setActiveTab: (index: number) => void;
};

const TabImage: FC<TabImageProps> = ({
  id,
  image,
  isActive,
  onActive,
  index,
  setActiveTab
}) => {
  const tabImageRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isActive) {
      onActive(tabImageRef);
    }
  }, [onActive, isActive]);

  if (!image) return null;

  return (
    <div
      ref={tabImageRef}
      id={id}
      className={cx(
        isActive ? `tab-image-wrapper__active` : `tab-image-wrapper__inactive`,
        'tab-image-wrapper'
      )}
      onClick={() => setActiveTab(index)}
    >
      <Image
        src={contentfulImgUtil(image.asset.url)}
        sizes="(max-width: 375px) 58vw, (max-width: 600px) 43vw, (max-width: 749px) 49vw, 20vw"
        srcSet={getSrcSet(image.asset.url)}
        alt={image.alt}
        className="tab-image"
      />
    </div>
  );
};

export default TabImage;
