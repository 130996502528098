import status from './status';
import error from './error';
import applicationUI from './ui/applicationUI';
import navUI from './ui/navUI';
import darkTheme from './ui/darkThemeUI';
import genericPage from './genericPage';
import article from './article';
import blog from './blog';
import modalPage from './modalPage';
import routerLocations from './routerLocations';
import compatibilityCheck from './compatibilityCheck';
import shopifyCart from './shopifyCart';
import shopifyCatalogue from './shopifyCatalogue';
import startPage from './startPage';

const reducer = {
  status,
  error,
  applicationUI,
  navUI,
  darkTheme,
  genericPage,
  article,
  blog,
  modalPage,
  routerLocations,
  compatibilityCheck,
  shopifyCart,
  shopifyCatalogue,
  startPage
};

export default reducer;
