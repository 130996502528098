import * as Types from '../../lib/types';

const LightIII: Types.Product = {
  _type: 'product',
  _id: 'lightiii',
  title: 'Light Phone III',
  plainTextBody: ['Moves at the speed of light, now with a camera.'],
  image: {
    _type: 'accessibleImage',
    _key: 'lightiii_image',
    asset: {
      _id: 'lightiii_image',
      url: '//images.ctfassets.net/tbduj203nkkk/6jxH5zjjuQrb1dgPHXbE9t/2bbc0e0ee035cbc826e661ffd76a937f/LightPhoneIII.Product.png',
      metadata: {
        lqip: '',
        dimensions: {
          aspectRatio: 1,
          width: 100,
          height: 100
        }
      }
    },
    alt: 'Product shot of the Light Phone III (front).'
  },
  ctas: [
    {
      _type: 'buttonLink',
      label: 'Learn more',
      link: {
        _type: 'link',
        internal: {
          _id: 'lightiii',
          _type: 'genericPage',
          slug: '/lightiii'
        },
        ariaLabel: 'Learn more about the Light Phone III'
      },
      variant: 'primary'
    },
    {
      _type: 'buttonLink',
      label: 'Buy',
      link: {
        _type: 'link',
        internal: {
          _id: 'light-phone-iii',
          _type: 'genericPage',
          slug: '/shop/products/light-phone-iii'
        },
        ariaLabel: 'Buy the Light Phone III'
      },
      variant: 'secondary'
    }
  ]
};

const LightII: Types.Product = {
  _type: 'product',
  _id: 'lightii',
  title: 'Light Phone II',
  plainTextBody: ['Our lightest phone with an E-ink screen.'],
  image: {
    _type: 'accessibleImage',
    _key: 'lightii_image',
    asset: {
      _id: 'lightii_image',
      url: '//images.ctfassets.net/tbduj203nkkk/743zr1qRkC8POkdrR0Sekz/b52475718f73db444054e5d3ad647c91/LightPhoneIIProduct.png',
      metadata: {
        lqip: '',
        dimensions: {
          aspectRatio: 1,
          width: 100,
          height: 100
        }
      }
    },
    alt: 'Product shot of the Light Phone II (front).'
  },
  ctas: [
    {
      _type: 'buttonLink',
      label: 'Learn more',
      link: {
        _type: 'link',
        internal: {
          _id: 'lightii',
          _type: 'genericPage',
          slug: '/lightii'
        },
        ariaLabel: 'Learn more about the Light Phone II'
      },
      variant: 'primary'
    },
    {
      _type: 'buttonLink',
      label: 'Buy',
      link: {
        _type: 'link',
        internal: {
          _id: 'light-phone-ii-black',
          _type: 'genericPage',
          slug: '/shop/products/light-phone-ii-black'
        },
        ariaLabel: 'Buy the Light Phone II'
      },
      variant: 'secondary'
    }
  ]
};

export const ProductBlockData: Types.ProductBlock = {
  _type: 'productBlock',
  _key: 'productBlock',
  isDarkMode: false,
  title:
    'There are two different Light Phones, depending on how light you want to go.',
  layout: 'layout-b',
  products: [LightIII, LightII],
  showBorderTop: false
};
