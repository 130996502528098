import React, { FC } from 'react';
import cx from 'classnames';

import * as Types from '../../lib/types';

type PaginationProps = {
  items: Types.AccordionItem[] | Types.Product[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  darkMode: boolean;
};

const Pagination: FC<PaginationProps> = ({
  items,
  activeTab,
  setActiveTab,
  darkMode
}) => {
  return (
    <ul className="pagination-wrapper" role="tablist">
      {items.map((item, index) => (
        <li
          role="tab"
          aria-selected={activeTab === index}
          aria-controls={`tab-content-${item._id}`}
          key={item._id}
          className="pagination-button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              setActiveTab(index);
            }
          }}
          onClick={() => {
            setActiveTab(index);
          }}
        >
          <span
            className={cx(
              'pagination-item',
              darkMode ? 'bg-color-white' : 'bg-color-medium-gray',
              activeTab === index
                ? 'pagination-item__active'
                : 'pagination-item__inactive'
            )}
          />
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
