import React, { FC, useState } from 'react';
import { Image } from 'components/base';
import { ButtonV2 } from 'components/base/Button/buttonV2';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { getSrcSet } from 'utils/getSrcSet';

import cx from 'classnames';
import BackgroundMedia from 'components/BackgroundMedia';
import Tabs from 'components/Tabs';
import contentfulImgUtil from 'utils/contentfulImgUtil';
import BlockDivider from 'components/BlockDivider';
import Richtext from 'models/richTextSerializer';

import * as Types from '../../lib/types';

type ProductBlockProps = {
  block: Types.ProductBlock;
};

const ProductBlock: FC<ProductBlockProps> = ({ block }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { isDarkMode, title, backgroundMedia, products, showBorderTop } = block;
  const itemCount = products.length;
  const isMobile = useMatchMedia('(max-width: 749px)');

  if (itemCount === 0) return null;
  return (
    <div
      className={cx(
        'product-block-wrapper',
        !backgroundMedia && 'bg-color-lightest-gray'
      )}
    >
      {backgroundMedia && (
        <div className="background-media">
          <div className="absolute t0 l0 w100 h100 bg-color-black-opacity-02" />
          <BackgroundMedia
            media={
              isMobile && backgroundMedia.mobile
                ? backgroundMedia.mobile
                : backgroundMedia.desktop
            }
          />
        </div>
      )}
      <div
        className={cx('product-block', backgroundMedia ? 'min-vh100' : 'v100')}
      >
        {showBorderTop && <BlockDivider isDarkMode={isDarkMode} />}
        <h2 className="content-block-title">{title}</h2>

        {/* Desktop */}
        <div className="products">
          {products.map((product) => (
            <div key={product._id} className="product">
              <div className="product-image">
                <Image
                  src={contentfulImgUtil(product.image.asset.url)}
                  sizes="20vw"
                  srcSet={getSrcSet(product.image.asset.url)}
                  alt={product.image.alt}
                />
              </div>
              <div className="product-content">
                <h3 className="product-title">{product.title}</h3>
                {product.body && (
                  <div className="product-description">
                    <Richtext content={product.body} />
                  </div>
                )}
                {product.plainTextBody && (
                  <div className="product-description">
                    {product.plainTextBody.map((p) => (
                      <p key={p}>{p}</p>
                    ))}
                  </div>
                )}
                <div className="product-ctas">
                  {product.ctas.map((cta, index) => (
                    <ButtonV2
                      key={index}
                      className="akkurat"
                      buttonType={cta.variant ? cta.variant : 'primary'}
                      to={
                        cta.link.internal
                          ? cta.link.internal.slug
                          : cta.link.external
                      }
                      ariaLabel={cta.link.ariaLabel}
                    >
                      {cta.label}
                    </ButtonV2>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Mobile */}
        <div className="product-tabs">
          <Tabs
            layout="layout-b"
            items={products}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            darkMode={isDarkMode}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductBlock;
