import React, { FC } from 'react';
import { Button, Image } from 'components/base';
import { getSrcSet } from 'utils/getSrcSet';

import cx from 'classnames';
import contentfulImgUtil from 'utils/contentfulImgUtil';
import RichText from 'models/richTextSerializer';

import * as Types from '../../lib/types';

type DetailsBlockProps = {
  block: Types.DetailsBlock;
};

const DetailsBlock: FC<DetailsBlockProps> = ({ block }) => {
  const { isDarkMode, detailSections } = block;

  return (
    <div
      className={cx(
        'details-block',
        isDarkMode ? 'bg-color-dark-gray' : 'bg-color-lightest-gray'
      )}
    >
      <div className="details-container">
        {/* Detail Group */}
        {detailSections.map((detailGroup) => (
          <div key={detailGroup._key} className="detailGroup">
            {/* Detail */}
            {detailGroup.detailItems.map((detail) => (
              <div key={detail._key} className="detail">
                {/* Plain TextTitle */}
                {detail.plainTextTitle && (
                  <>
                    {detail.titleLink ? (
                      <h2 className="detail-title">
                        <span>{detail.plainTextTitle}</span>
                        <Button
                          to={
                            detail.titleLink.internal
                              ? detail.titleLink.internal.slug
                              : detail.titleLink.external
                          }
                          wrapperClassName="block"
                          className="detail-title-link"
                          variant="text"
                          fontColor={isDarkMode ? 'white' : 'gray'}
                          ariaLabel={detail.titleLink.ariaLabel}
                        >
                          <span className="detail-title">
                            {detail.titleLink.label}
                          </span>
                        </Button>
                      </h2>
                    ) : (
                      <h2 className="detail-title">{detail.plainTextTitle}</h2>
                    )}
                  </>
                )}

                {/* Rich Text Title */}
                {detail.title && (
                  <h2 className="detail-title">
                    <RichText content={detail.title} className="detail-title" />
                  </h2>
                )}

                <div className="detail-content">
                  {/* Body */}
                  {detail.body && (
                    <RichText content={detail.body} className="detail-body" />
                  )}
                  {detail.plainTextBody && (
                    <div className="detail-body">
                      {detail.plainTextBody.map((p, index) => (
                        <p key={index}>{p}</p>
                      ))}
                    </div>
                  )}

                  {/* Image */}
                  {detail.image && detail.image.asset && (
                    <Image
                      src={contentfulImgUtil(detail.image.asset.url)}
                      sizes="15vw"
                      srcSet={getSrcSet(detail.image.asset.url)}
                      alt={detail.image.alt}
                      className="detail-image"
                    />
                  )}

                  {/* List */}
                  {detail.list && (
                    <ul
                      className={cx(
                        'detail-list',
                        detail.list.length >= 3 && 'detail-list-fadeOut'
                      )}
                      tabIndex={-1}
                    >
                      {detail.list.length >= 3 && (
                        <div
                          className="detail-list-overlay"
                          aria-hidden={true}
                        />
                      )}
                      {detail.list.map((item, index) => (
                        <Button
                          key={index}
                          to={
                            detail.titleLink
                              ? detail.titleLink.internal
                                ? detail.titleLink.internal.slug
                                : detail.titleLink.external
                              : ''
                          }
                          variant="text"
                          fontColor={isDarkMode ? 'white' : 'gray'}
                          ariaLabel={detail.titleLink?.ariaLabel}
                        >
                          <li className="detail-item">{item}</li>
                        </Button>
                      ))}
                    </ul>
                  )}

                  {/* Links */}
                  {detail.links && (
                    <div className="detail-links">
                      {detail.links.map((link, index) => (
                        <Button
                          key={index}
                          to={
                            link.link.internal
                              ? link.link.internal.slug
                              : link.link.external
                          }
                          wrapperClassName="detail-link"
                          className="detail-link"
                          variant="text"
                          font="akkurat"
                          fontColor={isDarkMode ? 'white' : 'gray'}
                          label={link.label}
                          ariaLabel={link.link.ariaLabel}
                        />
                      ))}
                    </div>
                  )}

                  {/* CTAs */}
                  {detail.ctas && (
                    <div className="detail-ctas">
                      {detail.ctas.map((cta, index) => (
                        <Button
                          key={index}
                          to={
                            cta.link.internal
                              ? cta.link.internal.slug
                              : cta.link.external
                          }
                          variant="secondary-v2"
                          label={cta.label}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailsBlock;
