import React, { FC } from 'react';
import { Image } from 'components/base';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { getSrcSet } from 'utils/getSrcSet';
import cx from 'classnames';
import contentfulImgUtil from 'utils/contentfulImgUtil';

import * as Types from '../../lib/types';

type SingleImageBlockProps = {
  block: Types.SingleImageBlock;
};

const SingleImageBlock: FC<SingleImageBlockProps> = ({ block }) => {
  const { isDarkMode, showBorderTop, image, imageSize } = block;

  const isMobile = useMatchMedia('(max-width: 750px)');
  return (
    <div
      className={cx(
        'image-block',
        isDarkMode ? 'bg-color-dark-gray' : 'bg-color-lightest-gray'
      )}
    >
      {showBorderTop && <div className="border-top" />}
      <div
        className={cx(
          'image-block__image-wrapper',
          image.applyScreenBlend && 'mix-blend-mode-screen'
        )}
        style={{
          width: isMobile
            ? imageSize === 'small'
              ? '9rem'
              : '80vw'
            : imageSize === 'small'
            ? '16rem'
            : '38.35rem'
        }}
      >
        <Image
          src={contentfulImgUtil(image.asset.url)}
          sizes={`(max-width: 750px) ${
            imageSize === 'small' ? '20vw' : '240px'
          }, ${imageSize === 'small' ? '13vw' : '828px'}`}
          srcSet={getSrcSet(image.asset.url)}
          alt={image.alt}
          className="image-block__image"
        />
      </div>
    </div>
  );
};

export default SingleImageBlock;
