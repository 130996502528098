import React, { FC } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import get from 'lodash.get';
import { TestimonialBlockData } from 'containers/HomepageContainers/TestimonialBlockContainer';
import {
  TextBlockData_1,
  TextBlockData_2,
  TextBlockData_3,
  TextBlockData_4,
  TextBlockData_5
} from 'containers/HomepageContainers/TextBlockContainer';
import {
  AccordionBlockData_1,
  AccordionBlockData_2
} from 'containers/HomepageContainers/AccordionBlockContainer';
import { ProductBlockData } from 'containers/HomepageContainers/ProductBlockContainer';
import { DetailsBlockData } from 'containers/HomepageContainers/DetailsBlockContainer';
import { SingleImageBlockData } from 'containers/HomepageContainers/SingleImageBlockContainer';

import ContentBlocks from 'components/ContentBlocks';
import SEO from 'components/SEO';

import * as Types from '../lib/types';
import { AppState } from 'state/types';
import cx from 'classnames';

type PropsFromRedux = ConnectedProps<typeof connector>;

const HomepageView: FC<PropsFromRedux> = ({ isDarkMode }) => {
  const homepage: Types.LightPageV2Content = {
    _type: 'lightPageV2',
    blocks: [
      TestimonialBlockData,
      TextBlockData_1,
      TextBlockData_2,
      TextBlockData_3,
      AccordionBlockData_1,
      AccordionBlockData_2,
      ProductBlockData,
      TextBlockData_4,
      TextBlockData_5,
      DetailsBlockData,
      SingleImageBlockData
    ]
  };

  const { blocks } = homepage;

  const homeSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'The Light Phone',
    url: 'https://www.thelightphone.com',
    logo: 'https://www.thelightphone.com/assets/images/light-logo.png',
    description:
      'Light is a radically different technology company. We design beautiful tools that respect and empower our users.'
  };

  return (
    <>
      <SEO
        title={homeSchema.name}
        description={homeSchema.description}
        schema={homeSchema}
      />
      <div
        className={cx(
          `HomepageView ${
            isDarkMode ? 'bg-color-dark-gray' : 'bg-color-lightest-gray'
          }`
        )}
      >
        <h1 className="sr-only">Homepage</h1>
        {/* Remove ContentBlocks until blocks have been created */}
        <ContentBlocks contentBlocks={blocks} />
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    ...state,
    isDarkMode: get(state, 'darkTheme.isDarkMode', false)
  };
};

const connector = connect(mapStateToProps);

export default connector(HomepageView);
