import { store } from 'store';
import { darkThemeOn, darkThemeOff } from 'state/actions/ui/darkThemeActions';
import { headerHeight } from 'components/Nav';

let lastThemeState: boolean | null = null; // Track the last theme state

export const setDarkMode = () => {
  try {
    const testPos = { x: window.innerWidth / 2, y: headerHeight / 2 };
    const backdropElements = document.elementsFromPoint(testPos.x, testPos.y);

    const section = backdropElements?.find(
      (element) => element.tagName === 'SECTION' || element.tagName === 'FOOTER'
    );

    const shouldBeDark = section?.classList.contains('dark') ?? false;

    // Only dispatch if the theme state has changed
    if (lastThemeState !== shouldBeDark) {
      lastThemeState = shouldBeDark;
      if (shouldBeDark) {
        store.dispatch(darkThemeOn());
      } else {
        store.dispatch(darkThemeOff());
      }
    }
  } catch (error) {
    // Fallback to light theme if there's an error
    if (lastThemeState !== false) {
      lastThemeState = false;
      store.dispatch(darkThemeOff());
    }
    console.warn('Error setting dark mode:', error);
  }
};
