import React, { FC, useRef } from 'react';
import cx from 'classnames';

import { TabsProps } from './index';
import Tablist from './Tablist';

type LayoutCProps = Omit<TabsProps, 'darkMode'>;

const LayoutC: FC<LayoutCProps> = ({
  layout,
  items,
  activeTab,
  setActiveTab,
  cta,
  className
}) => {
  const tabContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={tabContainerRef} className={cx('tab-wrapper', className)}>
      {/* Empty div to keep content centered */}
      <div className="empty-container" aria-label="" />

      <Tablist
        layout={layout}
        items={items}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        cta={cta}
      />

      {/* Empty div to keep content centered */}
      <div className="empty-container" aria-label="" />
    </div>
  );
};

export default LayoutC;
