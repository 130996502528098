import React, { FC, useState } from 'react';
import { useInView } from 'react-hook-inview';
import { useMatchMedia } from 'hooks/useMatchMedia';

import cx from 'classnames';
import BackgroundMedia from 'components/BackgroundMedia';
import Tabs from 'components/Tabs';
import BlockDivider from 'components/BlockDivider';

import * as Types from '../../lib/types';

type BackgroundMediaProps = {
  media:
    | {
        desktop: Types.AccessibleImage | Types.Video;
        mobile?: Types.AccessibleImage | Types.Video;
      }
    | undefined;
  isVisible: boolean;
};

const BackgroundMediaContainer: FC<BackgroundMediaProps> = ({
  media,
  isVisible
}) => {
  const isMobile = useMatchMedia('(max-width: 749px)');

  if (!media || media === undefined) return null;

  return (
    <div
      className={cx('background-media', isVisible ? 'opacity-1' : 'opacity-0')}
    >
      <div className="absolute t0 l0 w100 h100 bg-color-black-opacity-02" />
      <BackgroundMedia
        media={isMobile && media.mobile ? media.mobile : media.desktop}
      />
    </div>
  );
};

type AccordionBlockProps = {
  block: Types.AccordionBlock;
};

const AccordionBlock: FC<AccordionBlockProps> = ({ block }) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const {
    isDarkMode,
    backgroundMedia,
    title,
    accordionItems,
    cta,
    bottomSpacer,
    showBorderTop
  } = block;
  const itemsCount = accordionItems.length;

  const [ref, isVisible] = useInView({
    rootMargin: '0px 0px -50% 0px'
  });

  const overrideBackground =
    accordionItems.length > 0 && !!accordionItems[activeTab].backgroundMedia;

  if (itemsCount === 0) return null;

  return (
    <div
      ref={ref}
      className={cx(
        'accordion-block',
        backgroundMedia
          ? 'bg-transparent'
          : isDarkMode
          ? 'bg-color-dark-gray'
          : 'bg-color-lightest-gray'
      )}
    >
      {overrideBackground ? (
        <>
          {accordionItems.map((accordionItem, index) => {
            return (
              <BackgroundMediaContainer
                key={accordionItem._id}
                media={accordionItem.backgroundMedia}
                isVisible={activeTab === index && isVisible}
              />
            );
          })}
        </>
      ) : (
        <BackgroundMediaContainer
          media={backgroundMedia}
          isVisible={isVisible}
        />
      )}
      <div className={cx('body', backgroundMedia ? 'min-vh100' : 'h100')}>
        {showBorderTop && <BlockDivider isDarkMode={isDarkMode} />}
        <h2 className="content-block-title">{title}</h2>
        <div className="mt1_75 md:mt6">
          <Tabs
            layout="layout-a"
            items={accordionItems}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            cta={cta}
            darkMode={isDarkMode}
          />
        </div>
      </div>
      {bottomSpacer && <div className="content-block-spacer" />}
    </div>
  );
};

export default AccordionBlock;
