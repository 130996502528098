import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ConnectedProps, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setDarkMode } from 'utils/setDarkMode';

import cx from 'classnames';
import get from 'lodash.get';

import { openMenu } from 'state/actions/ui/navUIActions';
import { Button } from 'components/base';
import { ButtonV2 } from 'components/base/Button/buttonV2';
import { AppState, Dispatch } from 'state/types';
import { Route, Switch } from 'react-router-dom';
import { openCart } from 'state/actions/shopifyCartActions';
import { SVGMap } from 'svgs';
import { getNavSettings } from 'utils/getNavSettings';
import { isLightPageV2 } from 'constants/routes';

/**
 * Base height of header
 */
export const headerHeight = 91;

/**
 * Nav
 */
type PropsFromRedux = ConnectedProps<typeof connector>;

type NavProps = PropsFromRedux;

const Nav: FC<NavProps> = ({
  actions,
  location,
  genericPageSettings,
  modalPageIsOpen,
  isDarkMode,
  menuIsOpen
}) => {
  const { openMenu } = actions;
  const { key, pathname } = location;
  const lightPageV2 = isLightPageV2(pathname);
  const {
    navColor: navColorValue,
    buyButtonIsActive,
    menuButtonIsActive
  } = getNavSettings(pathname, genericPageSettings);
  /* Color values from Contentful are uppercased, convert the color here to handle it */
  const navColor = navColorValue.toLowerCase();

  /* Call setDarkMode when the page is loaded and when the user scrolls */
  useEffect(() => {
    if (!lightPageV2) return;
    setDarkMode();
    window.addEventListener('scroll', setDarkMode);
    return () => {
      window.removeEventListener('scroll', setDarkMode);
    };
  }, [lightPageV2]);

  /* Call setDarkMode when the menu is opened */
  useEffect(() => {
    if (!menuIsOpen) {
      setDarkMode();
    }
  }, [menuIsOpen]);

  /* Don't render the nav if the modal is open */
  if (modalPageIsOpen) return null;

  /* Don't render the nav if the user is on the Light Phone II product page */
  if (pathname.startsWith('/shop/products/light-phone-ii')) return null;

  return (
    <div id="MainNav" key={key} className="Nav fixed w100 z-nav events-none">
      <div
        className={cx(
          'nav-inner-wrapper w100 py_75 px1 flex flex-row items-center justify-between',
          isDarkMode ? 'color-white-opacity-02' : 'color-gray-opacity-02',
          {
            'Nav--hide-buy': !buyButtonIsActive
          }
        )}
      >
        <div className="z-nav">
          <Switch>
            <Route
              path={['/shop', '/cart']}
              /* On any of the /shop routes, the first
               * menu link should be to open the cart. */
              render={() => (
                <Button
                  ariaLabel="open cart"
                  label="cart"
                  to="/cart"
                  fontColor={
                    lightPageV2 ? (isDarkMode ? 'white' : 'gray') : navColor
                  }
                  font="nav"
                  className="nav-menu-item events-all z-overlay"
                />
              )}
            />
            <Route
              /* On all other routes, the first menu link
               * should navigate to the /shop page */
              render={() => (
                <Link
                  to="/"
                  aria-label="navigate to homepage"
                  className="events-all flex items-center"
                >
                  <SVGMap
                    icon="logo"
                    className="logo"
                    color={
                      lightPageV2
                        ? isDarkMode
                          ? 'color-white'
                          : 'color-medium-gray'
                        : navColor === 'white'
                        ? 'color-white'
                        : 'color-black'
                    }
                  />
                </Link>
              )}
            />
          </Switch>
        </div>
        <div>
          {menuButtonIsActive && (
            <Button
              ariaLabel="open menu"
              label="menu"
              onClick={openMenu}
              fontColor={
                lightPageV2 ? (isDarkMode ? 'white' : 'gray') : navColor
              }
              hover={lightPageV2 ? (isDarkMode ? 'white' : 'black') : navColor}
              font="nav"
              className="nav-menu-item nav-menu-item-hover events-all z-nav"
            />
          )}
          {lightPageV2 && buyButtonIsActive && (
            <ButtonV2
              to="/shop"
              ariaLabel="navigate to: shop"
              className="ml1_5 font-weight-500 futura-pt nav-menu-item"
              buttonType="primary"
            >
              shop
            </ButtonV2>
          )}
          {!lightPageV2 && buyButtonIsActive && (
            <Button
              ariaLabel="navigate to shop page"
              label="shop"
              to="/shop"
              fontColor={
                lightPageV2 ? 'gray' : navColor === 'white' ? 'gray' : 'black'
              }
              hover="black"
              color="white"
              font="nav"
              wrapperClassName="nav-menu-button inline-block ml1_25 px_625 pb_25 md:px1_5 md:pt_25 md:pb_438"
              className="nav-menu-item nav-menu-item-hover events-all z-nav"
            />
          )}
        </div>
      </div>
    </div>
  );
};

// TODO: Remove 'get' once redux store has been fully typed
const mapStateToProps = (state: AppState) => ({
  location: state.router.location,
  genericPageSettings: get(state, 'genericPage.genericPage.items[0].fields'),
  modalPageIsOpen: state.modalPage?.modalPageIsOpen,
  isDarkMode: get(state, 'darkTheme.isDarkMode', false),
  menuIsOpen: get(state, 'navUI.menuIsOpen', false)
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    actions: bindActionCreators(
      {
        openMenu,
        openCart
      },
      dispatch
    )
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Nav);
