import React, { FC, useRef } from 'react';
import cx from 'classnames';

import { Image } from 'components/base';

import contentfulImgUtil from 'utils/contentfulImgUtil';
import { getSrcSet } from 'utils/getSrcSet';

import { TabsProps } from './index';
import Tablist from './Tablist';
import Pagination from './Pagination';

type LayoutAProps = TabsProps & {
  darkMode: boolean;
};

const LayoutA: FC<LayoutAProps> = ({
  layout,
  items,
  activeTab,
  setActiveTab,
  cta,
  darkMode,
  className
}) => {
  const tabContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={tabContainerRef} className={cx('tab-wrapper', className)}>
      {/* Empty div to keep content centered */}
      <div className="empty-container" aria-label="" />

      <div className="tab-image-container-layout-a">
        {items.map((item, index) => {
          if (!item.image) return null;
          const hasImage = Boolean(item.image);

          return (
            <div
              key={item._id}
              id={`tab-content-${item._id}`}
              role="tabpanel"
              aria-hidden={activeTab !== index}
              className={cx(
                !hasImage
                  ? 'none'
                  : activeTab === index
                  ? `tab-image-wrapper__active`
                  : `tab-image-wrapper__inactive`,
                'tab-image-wrapper'
              )}
            >
              {hasImage && (
                <Image
                  src={contentfulImgUtil(item.image.asset.url)}
                  sizes="(max-width: 749px) 29vw, 28vw"
                  srcSet={getSrcSet(item.image.asset.url)}
                  alt={item.image.alt}
                  className="tab-image"
                />
              )}
            </div>
          );
        })}
      </div>

      {/* Pagination - only visible on mobile */}
      <Pagination
        items={items}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        darkMode={darkMode}
      />

      {/* Tabs */}
      <Tablist
        layout={layout}
        items={items}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        cta={cta}
      />
    </div>
  );
};

export default LayoutA;
