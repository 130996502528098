import { FETCH_START_PAGE } from 'state/actions/startPageActions';

import * as Types from 'lib/types';

interface StartPageState {
  startPage: Types.GenericPage | null;
}

const initialState: StartPageState = {
  startPage: null
};

type Action = {
  type: string;
  payload: Types.GenericPage;
};

const reducer = (state: StartPageState = initialState, action: Action) => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_START_PAGE}_FULFILLED`:
      return {
        ...state,
        startPage: payload
      };
    case `${FETCH_START_PAGE}_REJECTED`:
      console.log('Rejected with error:', payload);
      return {
        ...state,
        startPage: null
      };
    default:
      return state;
  }
};

export default reducer;
