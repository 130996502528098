import ContainerBase from 'lib/ContainerBase';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchStartPage } from 'state/actions/startPageActions';
import globalSettings from 'state/selectors/globalSettings';
import { AppState } from 'state/types';

class StartViewContainer extends ContainerBase {
  view: any = import('views/StartView');

  model = async () => {
    const { fetchStartPage } = this.props.actions;

    try {
      await fetchStartPage();
      return {
        isError: false
      };
    } catch (error) {
      console.error('Error fetching start page:', error);
      return {
        isError: true
      };
    }
  };
}

const mapStateToProps = (state: AppState) => ({
  globalSettings: globalSettings(state),
  startPage: state.startPage.startPage,
  fetchStartPageStatus: state.status.fetchStartPage
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  actions: bindActionCreators(
    {
      fetchStartPage
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(StartViewContainer);
