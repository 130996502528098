import React, { FC, useState } from 'react';
import { useInView } from 'react-hook-inview';
import { useMatchMedia } from 'hooks/useMatchMedia';
import { SVGMap } from 'svgs';
import { Button, Image, Video } from 'components/base';
import { ButtonV2 } from 'components/base/Button/buttonV2';
import { getSrcSet } from 'utils/getSrcSet';
import Richtext from 'models/richTextSerializer';

import cx from 'classnames';
import BackgroundMedia from 'components/BackgroundMedia';
import Modal from 'components/Modal';
import BlockDivider from 'components/BlockDivider';

import contentfulImgUtil from 'utils/contentfulImgUtil';

import * as Types from '../../lib/types';

type ForegroundMediaProps = {
  media: Types.Image | Types.Video | Types.AccessibleImage;
};

const ForegroundMedia: FC<ForegroundMediaProps> = ({ media }) => {
  switch (media._type) {
    case 'image':
      return (
        <Image
          src={contentfulImgUtil(media.url)}
          sizes="(max-width: 749px) 240px, 480px"
          srcSet={getSrcSet(media.url)}
          alt={media.alt}
          className="foreground-image"
        />
      );
    case 'accessibleImage':
      return (
        <Image
          src={media.asset.url}
          sizes="(max-width: 749px) 240px, 480px"
          srcSet={getSrcSet(media.asset.url)}
          alt={media.alt}
          className="foreground-image"
        />
      );
    case 'video':
      return (
        <div className="foreground-video">
          <Video
            video={media}
            width="100%"
            autoPlay={true}
            muted={true}
            controls={false}
            loop={true}
          />
        </div>
      );
  }
};

type TextBlockProps = {
  textBlock: Types.TextBlock;
};

const TextBlock: FC<TextBlockProps> = ({ textBlock }) => {
  const {
    title,
    isDarkMode,
    backgroundMedia,
    foregroundMedia,
    body,
    cta,
    showBorderTop,
    plainTextBody
  } = textBlock;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const isMobile = useMatchMedia('(max-width: 749px)');

  const [ref, isVisible] = useInView({
    rootMargin: '0px 0px -50% 0px'
  });

  return (
    <div
      ref={ref}
      className={cx(
        'w100 h100',
        backgroundMedia
          ? 'bg-transparent'
          : isDarkMode
          ? 'bg-color-dark-gray'
          : 'bg-color-lightest-gray'
      )}
    >
      {backgroundMedia && (
        <div
          className={cx(
            'background-media',
            isVisible ? 'opacity-1' : 'opacity-0'
          )}
        >
          <BackgroundMedia
            media={
              isMobile && backgroundMedia.mobile
                ? backgroundMedia.mobile
                : backgroundMedia.desktop
            }
            videoWidth="100%"
            videoHeight="100%"
          />
        </div>
      )}
      <div
        className={cx(
          'text-block',
          backgroundMedia ? 'min-vh100' : 'v100',
          foregroundMedia?.media.applyScreenBlend && 'mix-blend-mode-screen'
        )}
      >
        {showBorderTop && <BlockDivider isDarkMode={isDarkMode} />}
        <h2 className="content-block-title">{title}</h2>
        {(foregroundMedia || body) && (
          <div className="body-content">
            {foregroundMedia && (
              <div className="foreground-content">
                {foregroundMedia.media && foregroundMedia.video?.url ? (
                  <Button
                    ariaLabel={`open video modal: ${foregroundMedia.media.alt}`}
                    variant="text"
                    fontColor={isDarkMode ? 'white' : 'black'}
                    hover={isDarkMode ? 'white' : 'black'}
                    onClick={toggleModal}
                    className="w100"
                    innerClassName="w100"
                  >
                    <div className="foreground-media">
                      <ForegroundMedia media={foregroundMedia.media} />
                    </div>
                  </Button>
                ) : (
                  <div className="foreground-media">
                    <ForegroundMedia media={foregroundMedia.media} />
                  </div>
                )}
                {foregroundMedia && foregroundMedia.video?.url && (
                  <div className="foreground-cta">
                    <Button
                      ariaLabel={`open video modal: ${foregroundMedia.video.alt}`}
                      className={cx(
                        'label',
                        isDarkMode
                          ? 'label-underline-light'
                          : 'label-underline-dark'
                      )}
                      variant="text"
                      fontColor={isDarkMode ? 'white' : 'black'}
                      hover={isDarkMode ? 'white' : 'black'}
                      onClick={toggleModal}
                    >
                      <div className="play-button">
                        <SVGMap icon="play" />
                      </div>
                      <p className="ml1 flex-1">
                        {foregroundMedia.video.caption}
                      </p>
                    </Button>
                  </div>
                )}
              </div>
            )}

            {body && <Richtext content={body} className="body" />}

            {plainTextBody && (
              <div className="body">
                {plainTextBody.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              </div>
            )}

            {cta && (
              <div className="cta-wrapper">
                <ButtonV2
                  to={
                    cta.link.internal
                      ? cta.link.internal.slug
                      : cta.link.external
                  }
                  ariaLabel={cta.link.ariaLabel}
                  onClick={toggleModal}
                  buttonType={cta.variant ? cta.variant : 'tertiary-darkmode'}
                  className="cta-button"
                >
                  {cta.label}
                </ButtonV2>
              </div>
            )}
          </div>
        )}
      </div>

      {/* 
        Content block spacer should only render for blocks
        that have background and foreground media
       */}
      {backgroundMedia && foregroundMedia && (
        <div className="content-block-spacer" />
      )}
      {isModalOpen && foregroundMedia && foregroundMedia.video && (
        <div className="fixed t0 l0 vw100 vh100 z-overlay">
          <Modal
            backgroundColor="rgba(34, 34, 34, 0.9)"
            closeIconColor="white"
            onGoBack={toggleModal}
            className="flex justify-center items-center"
            innerWrapperStyleOverrides="modal-inner-wrapper"
            title="Video Modal"
          >
            <Video
              video={foregroundMedia.video}
              className="aspect-landscape md:w80"
              autoPlay={false}
              muted={false}
              controls={true}
              loop={false}
              width="100%"
              height="100%"
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default TextBlock;
