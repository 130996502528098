import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  type?: string; // article, website, product etc.
  schema?: object; // For JSON-LD structured data
}

/**
 * SEO Component for managing meta tags
 *
 * Note: React Helmet appends new meta tags to the document head rather than replacing existing ones.
 * This means:
 * 1. Default meta tags in index.html will remain
 * 2. New meta tags are added when this component renders
 * 3. Search engines and social media platforms typically use the last instance of each meta tag
 *
 * It is not optimal to have two sets of metatags in the index.html file,
 * but it will suffice until we replatform the site to Next.js.
 */
const SEO: React.FC<SEOProps> = ({
  title = 'The Light Phone',
  description = 'Light is a radically different technology company. We design beautiful tools that respect and empower our users and our first product is The Light Phone.',
  image = 'https://www.thelightphone.com/assets/images/social-share-card.jpg',
  url,
  type = 'website',
  schema
}) => {
  const siteTitle =
    title === 'The Light Phone' ? title : `${title} | The Light Phone`;

  const currentUrl =
    typeof window !== 'undefined'
      ? window.location.href
      : url || 'https://www.thelightphone.com';

  return (
    <Helmet
      defaultTitle="The Light Phone"
      titleTemplate="%s | The Light Phone"
      htmlAttributes={{
        lang: 'en'
      }}
    >
      {/* Override all meta tags */}
      <title>{title}</title>

      {/* Basic meta tags */}
      <meta name="description" content={description} />

      {/* OpenGraph tags */}
      <meta name="og:type" content={type} />
      <meta name="og:url" content={currentUrl} />
      <meta name="og:title" content={siteTitle} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      {/* JSON-LD structured data */}
      {schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
    </Helmet>
  );
};

export default SEO;
