import * as Types from '../../lib/types';

export const SingleImageBlockData: Types.SingleImageBlock = {
  _type: 'singleImageBlock',
  _key: 'single-image-block',
  isDarkMode: false,
  image: {
    _type: 'accessibleImage',
    _key: 'single-image-block',
    asset: {
      _id: '57JDkwjfveByyKs84GvZs7',
      url: '//images.ctfassets.net/tbduj203nkkk/57JDkwjfveByyKs84GvZs7/fed016331b10bc47fc9fa44017c49a9b/MuchLoveLight_FINAL.png',
      metadata: {
        lqip: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=',
        dimensions: {
          aspectRatio: 1,
          width: 1000,
          height: 1000
        }
      }
    },
    alt: 'Illustrated text that reads "Much Love, Light".'
  },
  imageSize: 'small',
  showBorderTop: false
};
