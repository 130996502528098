import 'custom-event-polyfill';
import XhrCache from '@spirit-fish/xhr-cache';

import React from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/browser';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import ShopifyProvider from './lib/Shopify/ShopifyProvider';

import { store, history } from 'store';

import App from 'App';

window.SPIRIT_FISH = false;
XhrCache.setup({
  shouldCache: (url) => {
    if (url.includes('api/carts/credit_checks')) return false;

    return (
      url.includes('vimeo.com') ||
      url.includes('lightphonecloud.com') ||
      url.includes('contentful.com')
    );
  },
  shouldLog: (url) => {
    return process.env.NODE_ENV !== 'production';
  },
  didError: (url, e, stage) => {
    Sentry.configureScope((scope) => {
      scope.setTag('xhr_cache_stage', stage);
      scope.setExtra('url', url);
      Sentry.captureException(e);
    });
  }
});

// Function to check the current subdomain
const getCurrentSubdomain = () => {
  if (!window) return null;

  const hostname = window.location.hostname;

  // If we're on localhost, there's no subdomain
  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return null;
  }

  // Split the hostname by dots and check for subdomain
  const parts = hostname.split('.');

  // If we have enough parts for a subdomain (e.g., sub.domain.com)
  if (parts.length > 2) {
    // The first part is the subdomain
    return parts[0];
  }

  return null;
};

if (getCurrentSubdomain() === 'start') {
  window.location.replace('https://support.thelightphone.com/hc/en-us/articles/36100487624980-Light-Phone-III-Introduction');
} else {
  const rootElement = document.getElementById('root');
  render(
    <Provider store={store}>
      <ShopifyProvider>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
      </ShopifyProvider>
    </Provider>,
    rootElement
  );
}
