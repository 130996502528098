import React, { FC, useState, FormEvent } from 'react';
import cx from 'classnames';

import BlockDivider from 'components/BlockDivider';

import * as Types from '../../lib/types';

type SearchBlockProps = {
  block: Types.SearchBlock;
};

const SUPPORT_BASE_URL = 'https://support.thelightphone.com/hc/en-us/search';

const SearchBlock: FC<SearchBlockProps> = ({ block }) => {
  const { isDarkMode, title, showBorderTop } = block;
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!searchQuery.trim()) {
      setError('Please enter a search term');
      return;
    }
    setError(null);

    try {
      const searchUrl = new URL(SUPPORT_BASE_URL);
      searchUrl.searchParams.set('utf8', '✓');
      searchUrl.searchParams.set('query', searchQuery.trim());
      const newWindow = window.open(
        searchUrl.toString(),
        '_blank',
        'noopener,noreferrer'
      );
      if (newWindow) {
        newWindow.opener = null;
      }
    } catch (error) {
      setError('Failed to construct search URL');
      console.error('Failed to construct search URL:', error);
    }
  };

  return (
    <div
      className={cx(
        'search-block',
        isDarkMode ? 'bg-color-dark-gray' : 'bg-color-lightest-gray'
      )}
    >
      <div className="body">
        {showBorderTop && <BlockDivider isDarkMode={isDarkMode} />}
        <h2 className="content-block-title">{title}</h2>
        <form
          onSubmit={handleSubmit}
          className="search-block__form"
          role="search"
          aria-label="Light Phone Support Search"
        >
          <div className="search-input-wrapper">
            <label htmlFor="search-input" className="search-input-label">
              Search for a topic
            </label>
            <input
              type="search"
              id="support-search"
              name="support-search"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                if (error) setError(null); // Clear error when user starts typing
              }}
              aria-label="Search support articles"
              aria-invalid={!!error}
              className={cx('search-input', { 'search-input--error': !!error })}
            />
            {error && (
              <div className="search-input-error" role="alert">
                {error}
              </div>
            )}
          </div>
          <button
            type="submit"
            className="search-submit"
            aria-label="Submit search"
            disabled={!searchQuery.trim()}
          >
            Search
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchBlock;
