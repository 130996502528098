import React, { FC } from 'react';
import cn from 'classnames';
import BlockContent from '@sanity/block-content-to-react';

import * as Types from '../lib/types';

type RichtextProps = {
  content: Types.RichText;
  className?: string;
};

const serializers = {
  types: {
    block: (props: any) => {
      const { style = 'normal' } = props.node;

      switch (style) {
        case 'h1':
          return <h1>{props.children}</h1>;
        case 'h2':
          return <h2>{props.children}</h2>;
        case 'h3':
          return <h3>{props.children}</h3>;
        case 'h4':
          return <h4>{props.children}</h4>;
        case 'blockquote':
          return <blockquote>{props.children}</blockquote>;
        default:
          return <p className="richtext-paragraph">{props.children}</p>;
      }
    }
  },
  marks: {
    strong: (props: any) => <strong>{props.children}</strong>,
    em: (props: any) => <em>{props.children}</em>,
    code: (props: any) => <code>{props.children}</code>
  },
  list: (props: any) => {
    const { type } = props;
    return type === 'bullet' ? (
      <ul>{props.children}</ul>
    ) : (
      <ol>{props.children}</ol>
    );
  },
  listItem: (props: any) => <li>{props.children}</li>
};

const Richtext: FC<RichtextProps> = ({ content, className }) => {
  if (!content) {
    console.error('Invalid content passed to Richtext:', content);
    return null;
  }

  return (
    <div className={cn('richtext', className)}>
      <BlockContent blocks={content} serializers={serializers} />
    </div>
  );
};

export default Richtext;
