import { FULFILLED, IDLE, PENDING, REJECTED } from 'constants/Status';
import { INITIALIZE_APPLICATION } from 'state/actions/applicationActions';
import { FETCH_GENERIC_PAGE } from 'state/actions/genericPageActions';
import { FETCH_ARTICLE_PAGE } from 'state/actions/articlePageActions';
import { FETCH_ALL_ARTICLES } from 'state/actions/allArticlesActions';
import { FETCH_MODAL_PAGE } from 'state/actions/modalPageActions';
import { FETCH_START_PAGE } from 'state/actions/startPageActions';

const initialState = {
  initializeApplication: IDLE,
  fetchGenericPage: IDLE,
  fetchArticlePage: IDLE,
  fetchAllArticles: IDLE,
  fetchModalPage: IDLE,
  createCreditCheck: IDLE,
  showCreditCheck: IDLE,
  fetchStartPage: IDLE
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case `${INITIALIZE_APPLICATION}_PENDING`:
      return { ...state, initializeApplication: PENDING };
    case `${INITIALIZE_APPLICATION}_FULFILLED`:
      return { ...state, initializeApplication: FULFILLED };
    case `${INITIALIZE_APPLICATION}_REJECTED`:
      return { ...state, initializeApplication: REJECTED };

    case `${FETCH_GENERIC_PAGE}_PENDING`:
      return { ...state, fetchGenericPage: PENDING };
    case `${FETCH_GENERIC_PAGE}_FULFILLED`:
      return { ...state, fetchGenericPage: FULFILLED };
    case `${FETCH_GENERIC_PAGE}_REJECTED`:
      return { ...state, fetchGenericPage: REJECTED };

    case `${FETCH_MODAL_PAGE}_PENDING`:
      return { ...state, fetchModalPage: PENDING };
    case `${FETCH_MODAL_PAGE}_FULFILLED`:
      return { ...state, fetchModalPage: FULFILLED };
    case `${FETCH_MODAL_PAGE}_REJECTED`:
      return { ...state, fetchModalPage: REJECTED };

    case `${FETCH_ARTICLE_PAGE}_PENDING`:
      return { ...state, fetchArticlePage: PENDING };
    case `${FETCH_ARTICLE_PAGE}_FULFILLED`:
      return { ...state, fetchArticlePage: FULFILLED };
    case `${FETCH_ARTICLE_PAGE}_REJECTED`:
      return { ...state, fetchArticlePage: REJECTED };

    case `${FETCH_ALL_ARTICLES}_PENDING`:
      return { ...state, fetchAllArticles: PENDING };
    case `${FETCH_ALL_ARTICLES}_FULFILLED`:
      return { ...state, fetchAllArticles: FULFILLED };
    case `${FETCH_ALL_ARTICLES}_REJECTED`:
      return { ...state, fetchAllArticles: REJECTED };

    case `${FETCH_START_PAGE}_PENDING`:
      return { ...state, fetchStartPage: PENDING };
    case `${FETCH_START_PAGE}_FULFILLED`:
      return { ...state, fetchStartPage: FULFILLED };
    case `${FETCH_START_PAGE}_REJECTED`:
      return { ...state, fetchStartPage: REJECTED };

    default:
      return state;
  }
};

export default reducer;
