import React, { FC } from 'react';
import cx from 'classnames';

type BlockDividerProps = {
  isDarkMode: boolean;
};

const BlockDivider: FC<BlockDividerProps> = ({ isDarkMode }) => {
  return (
    <div
      className={cx(
        'border-top',
        isDarkMode ? 'color-white-opacity-02' : 'color-gray'
      )}
    />
  );
};

export default BlockDivider;
