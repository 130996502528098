import React, { FC, useRef } from 'react';

import cx from 'classnames';
import FAQBlock from 'components/FAQBlock';
import TestimonialBlock from '../TestimonialBlock';
import TextBlock from 'components/TextBlock';
import AccordionBlock from 'components/AccordionBlock';
import ProductBlock from 'components/ProductBlock';
import DetailsBlock from 'components/DetailsBlock';
import SingleImageBlock from 'components/SingleImageBlock';
import SearchBlock from 'components/SearchBlock';

import * as Types from '../../lib/types';

type ContentBlocksProps = {
  contentBlocks: Types.ContentBlocks;
};

type ContentBlockProps = {
  block: Types.ContentBlockSection;
};

const ContentBlock: FC<ContentBlockProps> = ({ block }) => {
  switch (block._type) {
    case 'accordionBlock':
      return <AccordionBlock block={block} />;
    case 'detailsBlock':
      return <DetailsBlock block={block} />;
    case 'faqBlock':
      return <FAQBlock block={block} />;
    case 'productBlock':
      return <ProductBlock block={block} />;
    case 'searchBlock':
      return <SearchBlock block={block} />;
    case 'singleImageBlock':
      return <SingleImageBlock block={block} />;
    case 'testimonialBlock':
      return <TestimonialBlock block={block} />;
    case 'textBlock':
      return <TextBlock textBlock={block} />;
    default:
      //@ts-expect-error - exhaustiveness check
      console.error(`"${block._type}" is not a valid block type`);
      return null;
  }
};

const ContentBlocks: FC<ContentBlocksProps> = ({ contentBlocks }) => {
  const blockRef = useRef<HTMLElement>(null);

  /**
   * Check if block has background media (only blocks with background media should render full screen)
   */
  const isFullScreenBlock = (block: Types.ContentBlockSection) => {
    if (!block.backgroundMedia) {
      return false;
    }

    return true;
  };

  return (
    <div className="ContentBlocks">
      {contentBlocks.map((block) => (
        <section
          key={block._key}
          id={`${block._type}-${block._key}`}
          ref={blockRef}
          className={cx(
            'ContentBlock',
            isFullScreenBlock(block) && 'min-vh100',
            block.isDarkMode ? 'dark color-white' : 'light color-medium-gray'
          )}
        >
          <ContentBlock block={block} />
        </section>
      ))}
    </div>
  );
};

export default ContentBlocks;
