import React, { FC } from 'react';
import cx from 'classnames';

import { useMatchMedia } from 'hooks/useMatchMedia';

import { ButtonV2 } from 'components/base/Button/buttonV2';
import Richtext from 'models/richTextSerializer';

import * as Types from '../../lib/types';

type TablistProps = {
  layout: Types.AccordionLayout;
  items: Types.AccordionItem[] | Types.Product[];
  activeTab: number;
  setActiveTab: (index: number) => void;
  cta?: Types.ButtonLink;
};

const Tablist: FC<TablistProps> = ({
  layout,
  items,
  activeTab,
  setActiveTab,
  cta
}) => {
  const isMobile = useMatchMedia('(max-width: 749px)');

  return (
    <div className={cx(layout === 'layout-c' ? 'tabs-layout-c' : 'tabs')}>
      <ul className="tablist" role={isMobile ? undefined : 'tablist'}>
        {items.map((item, index) => (
          <li
            key={item._id}
            role={isMobile ? 'tabpanel' : 'presentation'}
            aria-hidden={isMobile ? activeTab !== index : undefined}
            className={cx(
              `tab-item`,
              activeTab === index ? `tab-item__active` : `tab-item__inactive`,
              {
                'text-center': layout === 'layout-b'
              }
            )}
          >
            <button
              id={`tab-header-${item._id}`}
              className="tab-item-title"
              onClick={() => setActiveTab(index)}
              role={isMobile ? undefined : 'tab'}
              aria-controls={isMobile ? undefined : `tab-content-${item._id}`}
              aria-label={
                activeTab === index
                  ? `Collapse tab ${index}: ${item.title}`
                  : `Expand tab ${index}: ${item.title}`
              }
              aria-selected={isMobile ? undefined : activeTab === index}
              tabIndex={0}
            >
              {item.title}
            </button>

            {item.body && (
              <div
                className={cx(
                  `tab-item-content-wrapper tab-item-content-wrapper${
                    activeTab === index ? '__active' : '__inactive'
                  }`
                )}
              >
                <Richtext content={item.body} className="tab-item-content" />
              </div>
            )}

            {item.plainTextBody && (
              <div
                className={cx(
                  `tab-item-content-wrapper tab-item-content-wrapper${
                    activeTab === index ? '__active' : '__inactive'
                  }`
                )}
              >
                {item.plainTextBody.map((p) => (
                  <div
                    key={item._id}
                    id={`tab-content-${item._id}`}
                    className="tab-item-content"
                    aria-hidden={isMobile ? undefined : activeTab !== index}
                    role={isMobile ? undefined : 'tabpanel'}
                    {...{ inert: index !== activeTab ? '' : undefined }}
                    tabIndex={index === activeTab ? 0 : -1}
                  >
                    {p}
                  </div>
                ))}
              </div>
            )}

            {item.ctas && item.ctas.length > 0 && (
              <div className="tab-ctas">
                {item.ctas.map((cta, index) => (
                  <ButtonV2
                    key={index}
                    // wrapperClassName="tab-item-cta"
                    className="tab-item-cta"
                    buttonType={cta.variant ? cta.variant : 'primary'}
                    to={
                      cta.link.internal
                        ? cta.link.internal.slug
                        : cta.link.external
                    }
                    ariaLabel={cta.link.ariaLabel}
                  >
                    {cta.label}
                  </ButtonV2>
                ))}
              </div>
            )}
          </li>
        ))}

        {/* CTA */}
        {cta && (
          <div className="tab-cta-wrapper">
            <ButtonV2
              className="tab-cta"
              to={
                cta.link.internal ? cta.link.internal.slug : cta.link.external
              }
              buttonType={cta.variant ? cta.variant : 'primary'}
              ariaLabel={cta.link.ariaLabel}
            >
              {cta.label}
            </ButtonV2>
          </div>
        )}
      </ul>
    </div>
  );
};

export default Tablist;
