import { sanityClient, GENERIC_PAGE_QUERY } from 'lib/sanity/sanity';

import * as Types from 'lib/types';

export const FETCH_START_PAGE = 'FETCH_START_PAGE';

export const fetchStartPage = () => (dispatch) => {
  return dispatch({
    type: FETCH_START_PAGE,
    payload: sanityClient
      .fetch<Types.GenericPage>(
        `*[_type == "genericPage" && slug.current == "start"][0] {${GENERIC_PAGE_QUERY}}`
      )
      .then((response) => {
        return response;
      })
  });
};
